import React, { useEffect, useState } from "react"

import PillButton from "./PillButton"
import { Color } from "../../../../constants/V2/color"
import { useIsBreakpoint } from "../../../../utils/V2/screen"

type Props = {
  text: string
  style?: "solid" | "bordered"
  color: Color
  overrideTextColor?: Color
  className?: string
}

const DownloadAppButton: React.FC<Props> = ({
  text,
  style = "solid",
  color,
  overrideTextColor,
  className,
}) => {
  const isDesktop = useIsBreakpoint("desktop")
  const [targetAppStore, setTargetAppStore] = useState("")

  // Make these env variables
  const desktopApp =
    process.env.GATSBY_APP_DESKTOP_URL || "https://app.yoco.com/login/landing"
  const appleStore =
    process.env.GATSBY_APP_APPLE_URL ||
    "https://apps.apple.com/za/app/yoco-run-grow-your-business/id892816357"
  const playStore =
    process.env.GATSBY_APP_ANDROID_URL ||
    "https://play.google.com/store/apps/details?id=za.co.yoco&hl=en_ZA"

  useEffect(() => {
    if (!isDesktop) {
      const isIOS =
        window.navigator.userAgent.indexOf("iPhone") > -1 ||
        window.navigator.userAgent.indexOf("iPod") > -1 ||
        window.navigator.userAgent.indexOf("iPad") > -1

      setTargetAppStore(isIOS ? appleStore : playStore)
    } else {
      setTargetAppStore(desktopApp)
    }
  }, [appleStore, desktopApp, isDesktop, playStore])

  return (
    <PillButton
      size="large"
      style={style}
      text={text}
      color={color}
      overrideTextColor={overrideTextColor}
      linkUrl={targetAppStore}
      className={className}
    />
  )
}

export default DownloadAppButton
