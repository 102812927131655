import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import RichMediaBanner from "../../../../V2/Sections/Banners/RichMedia"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

export default function StoryblokRichMediaBanner({
  blok,
}: Storyblok.BlokProps<Storyblok.RichMediaBanner>) {
  return (
    <RichMediaBanner
      titleText={blok.headerText}
      bodyText={blok.bodyText}
      buttonLink={getUrlFromStoryblokLink(blok.primaryCTALink)}
      buttonText={blok.primaryCTAText}
      buttonBackgroundColor={blok.primaryCTAColor}
      imageAlt={blok.image.alt}
      imageUrl={blok.image.filename}
      contentAlignment={blok.contentAlignment}
      textColor={blok.textColor}
      showDownloadAppButton={blok.showDownloadAppButton}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
